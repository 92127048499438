body, html, #root, .App {
  height: 100%;
};


.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    transform: scale(1);
    animation: App-logo-spin infinite 1.75s;
  }
}

.App-header {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: 'Barlow Condensed', sans-serif;
}

.App-header .affiant-blue {
  color: #192D41;
}
.reduced-font {
  position: relative;
  top: -7px;
}
.copy-right {
  display: block;
  font-family: sans-serif;
  font-weight: bold;
  margin-top: 5px;
}
.copy-right-tbb {
  display: block;
  font-family: sans-serif;
  font-weight: normal;
  margin-top: 5px;
}
p {
  margin-top: -10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
